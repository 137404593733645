import _cardTypes from "./lib/card-types";
import _addMatchingCardsToResults from "./lib/add-matching-cards-to-results";
import _isValidInputType from "./lib/is-valid-input-type";
import _findBestMatch from "./lib/find-best-match";
import _clone from "./lib/clone";
var exports = {};
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var cardTypes = _cardTypes;
var add_matching_cards_to_results_1 = _addMatchingCardsToResults;
var is_valid_input_type_1 = _isValidInputType;
var find_best_match_1 = _findBestMatch;
var clone_1 = _clone;
var customCards = {};
var cardNames = {
  VISA: "visa",
  MASTERCARD: "mastercard",
  AMERICAN_EXPRESS: "american-express",
  DINERS_CLUB: "diners-club",
  DISCOVER: "discover",
  JCB: "jcb",
  UNIONPAY: "unionpay",
  MAESTRO: "maestro",
  ELO: "elo",
  MIR: "mir",
  HIPER: "hiper",
  HIPERCARD: "hipercard"
};
var ORIGINAL_TEST_ORDER = [cardNames.VISA, cardNames.MASTERCARD, cardNames.AMERICAN_EXPRESS, cardNames.DINERS_CLUB, cardNames.DISCOVER, cardNames.JCB, cardNames.UNIONPAY, cardNames.MAESTRO, cardNames.ELO, cardNames.MIR, cardNames.HIPER, cardNames.HIPERCARD];
var testOrder = (0, clone_1.clone)(ORIGINAL_TEST_ORDER);
function findType(cardType) {
  return customCards[cardType] || cardTypes[cardType];
}
function getAllCardTypes() {
  return testOrder.map(function (cardType) {
    return (0, clone_1.clone)(findType(cardType));
  });
}
function getCardPosition(name, ignoreErrorForNotExisting) {
  if (ignoreErrorForNotExisting === void 0) {
    ignoreErrorForNotExisting = false;
  }
  var position = testOrder.indexOf(name);
  if (!ignoreErrorForNotExisting && position === -1) {
    throw new Error("\"" + name + "\" is not a supported card type.");
  }
  return position;
}
function creditCardType(cardNumber) {
  var results = [];
  if (!(0, is_valid_input_type_1.isValidInputType)(cardNumber)) {
    return results;
  }
  if (cardNumber.length === 0) {
    return getAllCardTypes();
  }
  testOrder.forEach(function (cardType) {
    var cardConfiguration = findType(cardType);
    (0, add_matching_cards_to_results_1.addMatchingCardsToResults)(cardNumber, cardConfiguration, results);
  });
  var bestMatch = (0, find_best_match_1.findBestMatch)(results);
  if (bestMatch) {
    return [bestMatch];
  }
  return results;
}
creditCardType.getTypeInfo = function (cardType) {
  return (0, clone_1.clone)(findType(cardType));
};
creditCardType.removeCard = function (name) {
  var position = getCardPosition(name);
  testOrder.splice(position, 1);
};
creditCardType.addCard = function (config) {
  var existingCardPosition = getCardPosition(config.type, true);
  customCards[config.type] = config;
  if (existingCardPosition === -1) {
    testOrder.push(config.type);
  }
};
creditCardType.updateCard = function (cardType, updates) {
  var originalObject = customCards[cardType] || cardTypes[cardType];
  if (!originalObject) {
    throw new Error("\"".concat(cardType, "\" is not a recognized type. Use `addCard` instead.'"));
  }
  if (updates.type && originalObject.type !== updates.type) {
    throw new Error("Cannot overwrite type parameter.");
  }
  var clonedCard = (0, clone_1.clone)(originalObject);
  clonedCard = __assign(__assign({}, clonedCard), updates);
  customCards[clonedCard.type] = clonedCard;
};
creditCardType.changeOrder = function (name, position) {
  var currentPosition = getCardPosition(name);
  testOrder.splice(currentPosition, 1);
  testOrder.splice(position, 0, name);
};
creditCardType.resetModifications = function () {
  testOrder = (0, clone_1.clone)(ORIGINAL_TEST_ORDER);
  customCards = {};
};
creditCardType.types = cardNames;
exports = creditCardType;
export default exports;