import _clone from "./clone";
import _matches from "./matches";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMatchingCardsToResults = void 0;
var clone_1 = _clone;
var matches_1 = _matches;
function addMatchingCardsToResults(cardNumber, cardConfiguration, results) {
  var i, patternLength;
  for (i = 0; i < cardConfiguration.patterns.length; i++) {
    var pattern = cardConfiguration.patterns[i];
    if (!(0, matches_1.matches)(cardNumber, pattern)) {
      continue;
    }
    var clonedCardConfiguration = (0, clone_1.clone)(cardConfiguration);
    if (Array.isArray(pattern)) {
      patternLength = String(pattern[0]).length;
    } else {
      patternLength = String(pattern).length;
    }
    if (cardNumber.length >= patternLength) {
      clonedCardConfiguration.matchStrength = patternLength;
    }
    results.push(clonedCardConfiguration);
    break;
  }
}
exports.addMatchingCardsToResults = addMatchingCardsToResults;
export default exports;